/* -------------------- Resets --------------------- */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body,
form,
fieldset,
ol,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
img {
  border: 0; /* kills Gecko bug when img's are placed inside links */
  vertical-align: bottom; /* set vertical align to bottom for IE */
}
/* ------------------- Defaults ------------------- */
html {
  overflow: auto; /* triggers 100% height in Opera 9.5 */
  overflow-y: scroll; /* creates a horizontal scrollbar, for a more consistent look & feel */
}
/* ------------------ Typography ------------------- */
p,
ul,
ol {
  font-size: 1em;
}
ul {
  list-style: none;
}
h1 {
  font-size: 1.6em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.4em;
}
h4 {
  font-size: 1.3em;
}
h5 {
  font-size: 1.2em;
}
h6 {
  font-size: 1.1em;
}
/* -------------------- Forms ---------------------- */
fieldset {
  border: none;
}
form :focus {
  outline: 0; /* removes Safari's blue border */
}
input,
select,
textarea {
  font-size: 1em;
}
label {
  cursor: pointer;
  vertical-align: middle;
}
input {
  vertical-align: middle;
}
textarea {
  overflow: auto; /* removes scrollbar from IE when none is needed */
}
/* ------------------- Links ---------------------- */
a {
  text-decoration: none;
  outline: 0; /* removes dotted border */
}
a:hover,
a:focus,
a:active,
a:visited {
  text-decoration: none;
}
/* ---------------- Global Classes --------------- */
.clear {
  clear: both;
  line-height: 0;
  height: 0;
  font-size: 0;
}
.clearfix:after {
  content: ' ';
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  display: inline-block;
}
/* mac hide \*/
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}
/* End hide */
