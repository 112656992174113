@import 'reset.scss';

$base-color: #fff;
$mobile-breakpoint: '980px';
$tablet-breakpoint: '1200px';
$desktop-breakpoint: '1400px';
$xxl-desktop-breakpoint: '2400px';

/* ------------------- Content ------------------- */
html {
  scroll-behavior: smooth !important;
}
body {
  font-family: 'Open Sans';
  background-color: #000;
  color: $base-color;
  padding: 0;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  font-size: 16px;

  @media screen and (min-width: $xxl-desktop-breakpoint) {
    font-size: 2vh;
  }
  > div {
    flex: 1;
  }
}

.hamburger-menu {
  position: fixed;
  top: 20px;
  right: 15px;
  z-index: 11;
  @media screen and (min-width: $mobile-breakpoint) {
    display: none;
  }
}
.top-menu {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 68px;
  @media screen and (min-width: $mobile-breakpoint) {
    background-color: transparent;
    justify-content: space-between;
    flex-direction: row;
  }

  @media screen and (min-width: $xxl-desktop-breakpoint) {
    height: 118px;
  }

  &-logo {
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: $mobile-breakpoint) {
      margin-left: 20%;
    }

    img {
      transition:
        width 0.5s ease,
        height 0.5s ease;
      width: 137px;

      @media screen and (min-width: $mobile-breakpoint) {
        width: 244px;
      }

      @media screen and (min-width: $xxl-desktop-breakpoint) {
        width: 400px;
      }
    }
  }

  &.visible {
    background-color: #000;
    height: 100%;
    justify-content: start;

    nav {
      display: block;
    }
  }

  &.sticky:not(.visible) {
    backdrop-filter: blur(2px);
    background-color: rgba(74, 70, 167, 0.24);
    @media screen and (min-width: $mobile-breakpoint) {
      height: 56px;
      @media screen and (min-width: $xxl-desktop-breakpoint) {
        height: 106px;
      }

      .top-menu-logo {
        img {
          width: 137px;

          @media screen and (min-width: $mobile-breakpoint) {
            width: 178px;
          }

          @media screen and (min-width: $xxl-desktop-breakpoint) {
            width: 250px;
          }
        }
      }
    }
  }

  nav {
    display: none;
    text-align: center;

    @media screen and (min-width: $mobile-breakpoint) {
      margin-right: 20%;
      display: block;
      position: relative;
      top: unset;
      text-align: unset;
    }

    .main-menu {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media screen and (min-width: $mobile-breakpoint) {
        flex-direction: row;
        gap: 40px;
      }

      a {
        background-color: transparent;
        color: $base-color;
      }
    }
  }
}

.container {
  width: 100%;
}

section {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .background {
    display: none;
    @media screen and (min-width: $mobile-breakpoint) {
      display: block;
      visibility: hidden;
      width: 100%;
    }
  }

  .mobile-background {
    width: 100%;
    margin: 0 auto;
    &.phone {
      width: 80%;
      margin-top: 0;
    }
    @media screen and (min-width: $mobile-breakpoint) {
      display: none;
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-flow: column nowrap;
    @media screen and (min-width: $mobile-breakpoint) {
      text-align: left;
    }

    h1 {
      font-family: 'M PLUS Rounded 1c', sans-serif;
      font-weight: 800;
      font-size: 2.5em;
      line-height: 1.5em;

      span {
        font-weight: 800;
      }
    }

    p,
    ul {
      font-size: 1em;
      line-height: 1.5em;
      text-align: left;

      @media screen and (min-width: $mobile-breakpoint) {
        font-size: 1em;
      }

      span {
        font-weight: 800;
      }
    }

    button,
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 59px;
      border-radius: 70px;
      font-size: 1em;
      color: $base-color;
      border: 0;
      margin-top: 3em;

      &:hover {
        cursor: pointer;
      }
      @media screen and (min-width: $mobile-breakpoint) {
        width: 350px;
      }

      @media screen and (min-width: $xxl-desktop-breakpoint) {
        width: 20vw;
        height: auto;
        padding: 1em;
      }
    }

    ul {
      list-style: disc;
      font-size: 1em;
      margin-top: 2em;
      margin-left: 15px;
    }
  }

  &#sectionHeader {
    background: #8896d5;
    background-size: cover !important;
    @media screen and (min-width: $mobile-breakpoint) {
      min-height: 1181px;
      background:
        url('./../assets/header.svg') no-repeat center center,
        #8896d5;
    }

    @media screen and (min-width: $xxl-desktop-breakpoint) {
      min-height: 120vh;
    }

    .content-wrapper {
      padding: 40px 40px 60px;
      margin-top: -5em;

      @media screen and (min-width: $tablet-breakpoint) {
        padding: 40px 40px 60px;
        margin-top: 0;
      }

      @media screen and (min-width: $mobile-breakpoint) {
        padding: 0 10% 0 65%;
        margin-top: 0;
      }
    }

    h1 {
      span {
        color: #2ae5f8;
      }
    }

    p,
    ul {
      color: #dbe1ff;
      font-size: 1.5em;
      line-height: 1.35em;
    }
  }

  &#sectionAboutUs {
    background: linear-gradient(270deg, #c0b4df, #ccc0e8);
    background-size: cover !important;
    @media screen and (min-width: $mobile-breakpoint) {
      min-height: 763px;
      background:
        url('./../assets/aboutUs.svg') no-repeat center bottom,
        linear-gradient(270deg, #c0b4df, #ccc0e8);
    }

    @media screen and (min-width: $xxl-desktop-breakpoint) {
      min-height: 80vh;
    }

    .content-wrapper {
      padding: 40px 40px 8px;

      @media screen and (min-width: $mobile-breakpoint) {
        padding: 0 57% 0 10%;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        padding: 0 57% 0 20%;
      }
    }

    h1 {
      color: #462b88;
      margin-bottom: 40px;
    }

    p,
    ul {
      color: #462b88;
    }

    button,
    .button {
      background-color: #756ffb;

      &:hover {
        background-color: #4b42ff;
      }
    }
  }

  &#sectionPhantomKey {
    background: linear-gradient(225.17deg, #74cfcb 19.04%, #c4fcf9 74.93%);

    @media screen and (min-width: $mobile-breakpoint) {
      min-height: 664px;
      background:
        url('../assets/phone_phantomKey.png') no-repeat 100% 0%/50%,
        linear-gradient(225.17deg, #74cfcb 19.04%, #c4fcf9 74.93%);
    }

    @media screen and (min-width: $desktop-breakpoint) {
      background:
        url('../assets/phone_phantomKey.png') no-repeat 75% 0%/30%,
        linear-gradient(225.17deg, #74cfcb 19.04%, #c4fcf9 74.93%);
    }

    @media screen and (min-width: $xxl-desktop-breakpoint) {
      min-height: 80vh;
    }

    .content-wrapper {
      padding: 40px 40px 8px;

      @media screen and (min-width: $mobile-breakpoint) {
        padding: 0 57% 0 10%;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        padding: 0 57% 0 20%;
      }
    }

    h1 {
      margin-bottom: 40px;
      img {
        width: 161px;
        @media screen and (min-width: $mobile-breakpoint) {
          width: 227px;
        }
        svg {
          width: 161px;
          @media screen and (min-width: $mobile-breakpoint) {
            width: 227px;
          }
        }

        @media screen and (min-width: $xxl-desktop-breakpoint) {
          width: 50%;
        }
      }
    }

    p,
    ul {
      color: #1f5856;
    }

    button,
    .button {
      background-color: #00b9af;

      &:hover {
        background-color: #00ddd2;
      }
    }
  }

  &#sectionMyFinances {
    background: linear-gradient(225.17deg, #79c48e 19.04%, #d6ffd6 74.93%);

    @media screen and (min-width: $mobile-breakpoint) {
      min-height: 664px;
      background:
        url('../assets/phone_finances.png') no-repeat 100% 0%/50%,
        linear-gradient(225.17deg, #79c48e 19.04%, #d6ffd6 74.93%);
    }

    @media screen and (min-width: $desktop-breakpoint) {
      background:
        url('../assets/phone_finances.png') no-repeat 75% 0%/30%,
        linear-gradient(225.17deg, #79c48e 19.04%, #d6ffd6 74.93%);
    }

    @media screen and (min-width: $xxl-desktop-breakpoint) {
      min-height: 80vh;
    }

    .content-wrapper {
      padding: 40px 40px 8px;

      @media screen and (min-width: $mobile-breakpoint) {
        padding: 0 57% 0 10%;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        padding: 0 57% 0 20%;
      }
    }

    h1 {
      color: #1f5856;
      margin-bottom: 40px;
    }

    p,
    ul {
      color: #1f5856;
    }

    button,
    .button {
      background-color: #43b662;

      &:hover {
        background-color: #32d25e;
      }
    }
  }

  &#sectionContact {
    background: linear-gradient(249.59deg, #90d9ee 0%, #b8e3ef 100.68%);

    @media screen and (min-width: $mobile-breakpoint) {
      min-height: 525px;
    }

    @media screen and (min-width: $xxl-desktop-breakpoint) {
      min-height: 50vh;
    }

    .content-wrapper {
      padding: 40px;
      text-align: center;
      @media screen and (min-width: $mobile-breakpoint) {
        padding: 0 23%;
      }
    }

    h1 {
      color: #184499;
      margin-bottom: 20px;
    }

    input {
      width: 100%;
      border: 2px solid #799eb9;
      border-radius: 24px;
      background-color: transparent;
      margin-bottom: 20px;
      padding: 15px 15px 15px 50px;
      color: #2e5f83;
      height: 47px;
      @media screen and (min-width: $xxl-desktop-breakpoint) {
        height: auto;
        background-size: 23px !important;
        padding-left: 60px;
      }

      &:hover {
        border-color: #4970bc;
        color: #4970bc;
      }

      &:focus,
      &:active {
        border-color: #184499;
        color: #184499;
        background-color: #ccf4ff;
      }

      &.subject {
        padding: 15px;
      }

      &.name {
        background: url('../assets/icons/user.svg') no-repeat 20px;
        &:hover {
          background: url('../assets/icons/user_hover.svg') no-repeat 20px;
        }

        &:focus,
        &:active {
          background:
            url('../assets/icons/user_focus.svg') no-repeat 20px,
            #ccf4ff;
        }
      }

      &.email {
        background: url('../assets/icons/email.svg') no-repeat 20px;
        &:hover {
          background: url('../assets/icons/email_hover.svg') no-repeat 20px;
        }

        &:focus,
        &:active {
          background:
            url('../assets/icons/email_focus.svg') no-repeat 20px,
            #ccf4ff;
        }
      }

      &.phone {
        background: url('../assets/icons/phone.svg') no-repeat 20px;
        &:hover {
          background: url('../assets/icons/phone_hover.svg') no-repeat 20px;
        }

        &:focus,
        &:active {
          background:
            url('../assets/icons/phone_focus.svg') no-repeat 20px,
            #ccf4ff;
        }
      }
    }

    textarea {
      font-family: 'Open Sans';
      width: 100%;
      height: 30vh;
      border: 2px solid #799eb9;
      border-radius: 24px;
      background-color: transparent;
      padding: 15px;
      @media screen and (min-width: $mobile-breakpoint) {
        height: 100%;
      }

      &:hover {
        border-color: #4970bc;
        color: #4970bc;
      }

      &:focus,
      &:active {
        border-color: #184499;
        color: #184499;
        background-color: #ccf4ff;
      }
    }

    .form {
      &-wrapper {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $mobile-breakpoint) {
          flex-direction: row;

          > div:nth-child(1) {
            width: 40%;

            input:last-of-type {
              margin-bottom: 0;
            }
          }

          > div:nth-child(2) {
            margin-left: 20px;
            width: calc(60% - 20px);
          }
        }
      }

      &-sumbit-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        button,
        .button {
          margin-top: 40px;
          background-color: #184499;

          &:hover {
            background-color: #1d58c9;
          }
        }
      }
    }
  }
}

.footer {
  background: linear-gradient(85.46deg, #a9c1f0 0%, #8fadfa 100%);
  color: #171543;
  width: 100%;
  height: 100%;
  padding: 35px 40px 80px;
  display: flex;
  justify-content: start;
  flex-direction: column-reverse;
  @media screen and (min-width: $mobile-breakpoint) {
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 20% 100px;
  }

  h6 {
    margin-bottom: 1.2em;
  }

  &-desc {
    position: relative;
    @media screen and (min-width: $mobile-breakpoint) {
      width: 55%;
    }

    &-logo {
      margin-bottom: 23px;

      img {
        transition:
          width 0.5s ease,
          height 0.5s ease;
        width: 137px;

        @media screen and (min-width: $mobile-breakpoint) {
          width: 244px;
        }

        @media screen and (min-width: $xxl-desktop-breakpoint) {
          width: 400px;
        }
      }
    }

    p {
      @media screen and (min-width: $mobile-breakpoint) {
        max-width: 375px;
      }

      @media screen and (min-width: $xxl-desktop-breakpoint) {
        max-width: 70%;
      }

      &.copy {
        font-size: 0.9em;
        margin-top: 50px;
        @media screen and (min-width: $mobile-breakpoint) {
          margin-top: 0;
          position: absolute;
          bottom: 0;
        }

        span {
          font-weight: 600;
        }
      }
    }
  }

  &-menu {
    margin-bottom: 80px;
    @media screen and (min-width: $mobile-breakpoint) {
      margin-bottom: 0;
      padding-left: 20px;
      width: 20%;
    }

    nav {
      .menu {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 1em;

        a {
          background-color: transparent;
          color: #171543;
        }
      }
    }
  }

  &-contact {
    margin-bottom: 50px;
    @media screen and (min-width: $mobile-breakpoint) {
      margin-bottom: 0;
      padding-left: 20px;
      width: 25%;
    }

    p {
      font-size: 0.9em;
      margin-bottom: 8px;
      display: block;

      &.info {
        font-size: 1.2em;
        font-weight: 600;
      }

      &.desc {
        font-size: 1em;
        margin-bottom: 46px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
